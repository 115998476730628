import { HashLink as Lnk } from 'react-router-hash-link';
import {FormattedMessage,useIntl} from 'react-intl';
import Newscard from './subcomponents/Newscard';
import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import '../styles/allnews.css'


const Allnews = () => {
    const intl = useIntl()
    useEffect(()=>window.scrollTo({top: 0}),[])
    const transreload = (locale) => {
        localStorage.setItem('locale',locale)
        window.location.reload()
    }
    return (
        <div className='all-news-page'>
            <div className='news-page-header'>
                <div className='main-header-head'>
                    {localStorage.getItem('locale')=='ru-RU'?
                        (<div className='main-header-language' onClick={()=>transreload('en-US')}>
                            <img className='lang-img' src={require('../images/lang-en.png')} alt=""/>
                        </div>
                        ):(<div className='main-header-language' onClick={()=>transreload('ru-RU')}>
                            <img className='lang-img' src={require('../images/lang-ru.png')} alt=""/>
                        </div>)}
                        <div className='main-header-auth'><Link to='/login' className='auth-button'><button className='auth-button'><FormattedMessage id="main_auth_btn"/></button></Link></div>
                </div>
                <div className='main-header-nav'>
                    <div className='main-header-link'>
                        <Lnk className='link' to="/main"><FormattedMessage id="allnews_link_main"/></Lnk>
                        <Lnk className='link' to="/about"><FormattedMessage id="main_link_about"/></Lnk>
                        <Lnk className='link' to="/main/#test"><FormattedMessage id="main_link_test"/></Lnk>
                        <Lnk className='link' to="/main/#partner"><FormattedMessage id="main_link_partner"/></Lnk>
                        <Lnk className='link' to="/main/#contact"><FormattedMessage id="main_link_contacts"/></Lnk>
                        <Lnk className='link' to="/documents"><FormattedMessage id="main_link_documents"/></Lnk>                   
                    </div>
                </div>
                <div className='news-page-title'><FormattedMessage id="main_news_title"/></div>
            </div>
            <div className='news-page-content'>
                <div className='news-content-cards'>
                    <Newscard img='news-37.png' text = {intl.formatMessage({id:'news_37_main_title'})} id='37' date='20.08.2024' type={intl.formatMessage({id:'news_press_release'})}/>
                    <Newscard img='news-36.png' text = {intl.formatMessage({id:'news_36_main_title'})} id='36' date='30.05.2024' type={intl.formatMessage({id:'news_press_release'})}/>
                    <Newscard img='news-35.png' text = {intl.formatMessage({id:'news_35_main_title'})} id='35' date='17.04.2024' type={intl.formatMessage({id:'news_press_release'})}/>
                    <Newscard img='news-34.png' text = {intl.formatMessage({id:'news_34_main_title'})} id='34' date='08.04.2024' type={intl.formatMessage({id:'news_press_release'})}/>
                    <Newscard img='news-33.png' text = {intl.formatMessage({id:'news_33_main_title'})} id='33' date='09.02.2024' type={intl.formatMessage({id:'news_press_release'})}/>
                    <Newscard img='news-32.png' text = {intl.formatMessage({id:'news_32_main_title'})} id='32' date='26.12.2023' type={intl.formatMessage({id:'news_press_release'})}/>
                    <Newscard img='news-31.png' text = {intl.formatMessage({id:'news_31_main_title'})} id='31' date='21.12.2023' type={intl.formatMessage({id:'news_press_release'})}/>
                    <Newscard img='news-30.png' text = {intl.formatMessage({id:'news_30_main_title'})} id='30' date='08.12.2023' type={intl.formatMessage({id:'news_press_release'})}/>
                    <Newscard img='news-29.png' text = {intl.formatMessage({id:'news_29_main_title'})} id='29' date='28.11.2023' type={intl.formatMessage({id:'news_press_release'})}/>
                    <Newscard img='news-28.png' text = {intl.formatMessage({id:'news_28_main_title'})} id='28' date='16.10.2023' type={intl.formatMessage({id:'news_press_release'})}/>
                    <Newscard img='news-27.png' text = {intl.formatMessage({id:'news_27_main_title'})} id='27' date='06.10.2023' type={intl.formatMessage({id:'news_press_release'})}/>
                    <Newscard img='news-26.png' text = {intl.formatMessage({id:'news_26_main_title'})} id='26' date='06.10.2023' type={intl.formatMessage({id:'news_press_release'})}/>
                    <Newscard img='news-25.png' text = {intl.formatMessage({id:'news_25_main_title'})} id='25' date='23.09.2023' type={intl.formatMessage({id:'news_press_release'})}/>
                    <Newscard img='news-24.png' text = {intl.formatMessage({id:'news_24_main_title'})} id='24' date='23.09.2023' type={intl.formatMessage({id:'news_press_release'})}/>
                    <Newscard img='news-23.png' text = {intl.formatMessage({id:'news_23_main_title'})} id='23' date='19.09.2023' type={intl.formatMessage({id:'news_press_release'})}/>
                    <Newscard img='news-22.png' text = {intl.formatMessage({id:'news_22_main_title'})} id='22' date='06.09.2023' type={intl.formatMessage({id:'news_press_release'})}/>
                    <Newscard img='news-21.png' text = {intl.formatMessage({id:'news_21_main_title'})} id='21' date='01.08.2023' type={intl.formatMessage({id:'news_press_release'})}/>
                    <Newscard img='news-20.jpg' text = {intl.formatMessage({id:'news_20_main_title'})} id='20' date='21.07.2023' type={intl.formatMessage({id:'news_press_release'})}/>
                    <Newscard img='news-19.png' text = {intl.formatMessage({id:'news_19_main_title'})} id='19' date='04.07.2023' type={intl.formatMessage({id:'news_press_release'})}/>
                    <Newscard img='news-18.png' text = {intl.formatMessage({id:'news_18_main_title'})} id='18' date='23.06.2023' type={intl.formatMessage({id:'news_press_release'})}/>
                    <Newscard img='news-17.png' text = {intl.formatMessage({id:'news_17_main_title'})} id='17' date='16.06.2023' type={intl.formatMessage({id:'news_press_release'})}/>
                    <Newscard img='news-16.png' text = {intl.formatMessage({id:'news_16_main_title'})} id='16' date='14.06.2023' type={intl.formatMessage({id:'news_press_release'})}/>
                    <Newscard img='news-9.png' text = {intl.formatMessage({id:'news_9_title'})} id='15' date='09.06.2023' type={intl.formatMessage({id:'news_press_release'})}/>
                    <Newscard img='news-14.png' text = {intl.formatMessage({id:'news_14_main_title'})} id='14' date='24.05.2023' type={intl.formatMessage({id:'news_press_release'})}/>
                    <Newscard img='news-13.png' text = {intl.formatMessage({id:'news_13_main_title'})} id='13' date='18.05.2023' type={intl.formatMessage({id:'news_press_release'})}/>
                    <Newscard img='news-12.png' text = {intl.formatMessage({id:'news_12_main_title'})} id='12' date='11.05.2023' type={intl.formatMessage({id:'news_press_release'})}/>
                    <Newscard img='news-11.png' text = {intl.formatMessage({id:'news_11_main_title'})} id='11' date='28.04.2023' type={intl.formatMessage({id:'news_press_release'})}/>
                    <Newscard img='news-10.png' text = {intl.formatMessage({id:'news_10_main_title'})} id='10' date='24.04.2023' type={intl.formatMessage({id:'news_press_release'})}/>
                    <Newscard img='news-9.png' text = {intl.formatMessage({id:'news_9_title'})} id='9' date='14.04.2023' type={intl.formatMessage({id:'news_press_release'})}/>
                    <Newscard img='news-8.png' text = {intl.formatMessage({id:'news_8_main_title'})} id='8' date='22.03.2023' type={intl.formatMessage({id:'news_press_release'})}/>
                    <Newscard img='news-7.png' text = {intl.formatMessage({id:'main_news_7_title'})} id='7' date='10.12.2022' type={intl.formatMessage({id:'news_press_release'})}/>
                    <Newscard img='news-5.png' text = {intl.formatMessage({id:'main_news_5_title'})} id='5' date='25.09.2022' type={intl.formatMessage({id:'news_press_release'})}/>
                    <Newscard img='news-6.png' text = {intl.formatMessage({id:'main_news_6_title'})} id='6' date='10.09.2022' type={intl.formatMessage({id:'news_press_release'})}/>
                    <Newscard img='news-4.png' text = {intl.formatMessage({id:'main_news_4_title'})} id='4' date='11.02.2022' type={intl.formatMessage({id:'news_press_release'})}/>
                    <Newscard img='news-2.png' text = {intl.formatMessage({id:'main_news_2_title'})} id='2' date='20.02.2022' type={intl.formatMessage({id:'news_press_release'})}/>
                    <Newscard img='news-1.png' text = {intl.formatMessage({id:'main_news_1_title'})} id='1' date='11.02.2022' type={intl.formatMessage({id:'news_press_release'})}/>
                    <Newscard img='news-3.png' text = {intl.formatMessage({id:'main_news_3_title'})} id='3' date='04.07.2020' type={intl.formatMessage({id:'news_press_release'})}/>
                </div>
            </div>
            {(document.documentElement.scrollWidth>500)?(
                    <div className='about-footer'>
                        <div className='about-footer-content'>
                            <div className='about-ftizis-info'><FormattedMessage id="main_ftizisbiomed"/></div>
                            <div className='about-social'>
                                <a href='https://vk.com/club214630195' target="_blank" rel="noreferrer"><img className='soc-img' src = {require('./subcomponents/img/vk.png')} alt=""/></a>
                                <a href='https://t.me/FtizisBioMed' target="_blank" rel="noreferrer"><img className='soc-img' src = {require('./subcomponents/img/tel.png')} alt=""/></a>
                            </div>
                            <div className='about-vector-info'><FormattedMessage id="main_vector"/></div>
                        </div>
                    </div>
                ):(
                    <div className='about-footer'>
                        <div className='about-footer-content'>
                            <div className='about-social'>
                                    <a href='https://vk.com/club214630195' target="_blank" rel="noreferrer"><img className='soc-img' src = {require('./subcomponents/img/vk.png')} alt=""/></a>
                                    <a href='https://t.me/FtizisBioMed' target="_blank" rel="noreferrer"><img className='soc-img' src = {require('./subcomponents/img/tel.png')} alt=""/></a>
                                </div>
                            <div className='about-ftizis-info'><FormattedMessage id="main_ftizisbiomed"/></div>
                            <div className='about-vector-info'><FormattedMessage id="main_vector"/></div>
                        </div>
                    </div>
                )}
        </div>
    );
};

export default Allnews;