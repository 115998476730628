import Admin from "./pages/Admin"
import User from "./pages/User"
import About from "./pages/About"
import Auth from "./pages/Auth"
import Main from "./pages/Main"
import Allnews from "./pages/Allnews"
import Registration from "./pages/Registration"
import News1 from './pages/News/News1'
import News2 from './pages/News/News2'
import News3 from './pages/News/News3'
import News4 from './pages/News/News4'
import News5 from './pages/News/News5'
import News6 from './pages/News/News6'
import News7 from './pages/News/News7'
import News8 from './pages/News/News8'
import News9 from './pages/News/News9'
import News10 from './pages/News/News10'
import News11 from './pages/News/News11'
import News12 from './pages/News/News12'
import News13 from './pages/News/News13'
import News14 from './pages/News/News14'
import News15 from './pages/News/News15'
import News16 from './pages/News/News16'
import News17 from './pages/News/News17'
import News18 from './pages/News/News18'
import News19 from './pages/News/News19'
import News20 from './pages/News/News20'
import News21 from './pages/News/News21'
import News22 from './pages/News/News22'
import News23 from './pages/News/News23'
import News24 from './pages/News/News24'
import News25 from './pages/News/News25'
import News26 from './pages/News/News26'
import News27 from './pages/News/News27'
import News28 from './pages/News/News28'
import News29 from './pages/News/News29'
import News30 from './pages/News/News30'
import News31 from './pages/News/News31'
import News32 from './pages/News/News32'
import News33 from './pages/News/News33'
import News34 from './pages/News/News34'
import News35 from './pages/News/News35'
import News36 from './pages/News/News36'
import News37 from './pages/News/News37'


import Testpage from "./pages/subcomponents/Testpage"
import Success from "./pages/Success"
import Fail from "./pages/Fail"
import Individual from "./pages/Individual"
import Documents from "./pages/Documents"
export const authRoutes = [
    {
        path:'/',
        component:Main,
    },
    {
        path:'/main',
        component:Main,
    },
    {
        path:'/test/:id',
        component:Testpage,
    },
    {
        path:'user/*',
        component:User,
    },
    {
        path:'individual/*',
        component:Individual,
    },
    {
        path:'admin/*',
        component:Admin,
    },
    {
        path:'/about',
        component:About,
    },
    {
        path:'login',
        component:Auth,
    },
    {
        path:'news/',
        component:Allnews,
    },
    // {
    //     path:'registration',
    //     component:Registration,
    // },
    {
        path:'success',
        component:Success,
    },
    {
        path:'fail',
        component:Fail,
    },
    {
        path:'/documents',
        component:Documents,
    },
    {
        path:'/news/1',
        component:News1,
    },
    {
        path:'/news/2',
        component:News2,
    },
    {
        path:'/news/3',
        component:News3,
    },
    {
        path:'/news/4',
        component:News4,
    },
    {
        path:'/news/5',
        component:News5,
    },
    {
        path:'/news/6',
        component:News6,
    },
    {
        path:'/news/7',
        component:News7,
    },
    {
        path:'/news/8',
        component:News8,
    },
    {
        path:'/news/9',
        component:News9,
    },
    {
        path:'/news/10',
        component:News10,
    },
    {
        path:'/news/11',
        component:News11,
    },
    {
        path:'/news/12',
        component:News12,
    },
    {
        path:'/news/13',
        component:News13,
    },
    {
        path:'/news/14',
        component:News14,
    },
    {
        path:'/news/15',
        component:News15,
    },
    {
        path:'/news/16',
        component:News16,
    },
    {
        path:'/news/17',
        component:News17,
    },
    {
        path:'/news/18',
        component:News18,
    },
    {
        path:'/news/19',
        component:News19,
    },
    {
        path:'/news/20',
        component:News20,
    },
    {
        path:'/news/21',
        component:News21,
    },
    {
        path:'/news/22',
        component:News22,
    },
    {
        path:'/news/23',
        component:News23,
    },
    {
        path:'/news/24',
        component:News24,
    },
    {
        path:'/news/25',
        component:News25,
    },
    {
        path:'/news/26',
        component:News26,
    },
    {
        path:'/news/27',
        component:News27,
    },
    {
        path:'/news/28',
        component:News28,
    },
    {
        path:'/news/29',
        component:News29,
    },
    {
        path:'/news/30',
        component:News30,
    },
    {
        path:'/news/31',
        component:News31,
    },
    {
        path:'/news/32',
        component:News32,
    },
    {
        path:'/news/33',
        component:News33,
    },
    {
        path:'/news/34',
        component:News34,
    },
    {
        path:'/news/35',
        component:News35,
    },
    {
        path:'/news/36',
        component:News36,
    },
    {
        path:'/news/37',
        component:News37,
    },
]
export const publicRoutes = [
    {
        path:'/',
        component:Main,
    },
    {
        path:'/main',
        component:Main,
    },
    {
        path:'/news/1',
        component:News1,
    },
    {
        path:'/news/2',
        component:News2,
    },
    {
        path:'/test/:id',
        component:Testpage,
    },
    {
        path:'/news/3',
        component:News3,
    },
    {
        path:'/news/4',
        component:News4,
    },
    {
        path:'/news/5',
        component:News5,
    },
    {
        path:'/news/6',
        component:News6,
    },
    {
        path:'/news/7',
        component:News7,
    },
    {
        path:'/news/8',
        component:News8,
    },
    {
        path:'/news/9',
        component:News9,
    },
    {
        path:'/news/10',
        component:News10,
    },
    {
        path:'/news/11',
        component:News11,
    },
    {
        path:'/news/12',
        component:News12,
    },
    {
        path:'/news/13',
        component:News13,
    },
    {
        path:'/news/14',
        component:News14,
    },
    {
        path:'/news/15',
        component:News15,
    },
    {
        path:'/news/16',
        component:News16,
    },
    {
        path:'/news/17',
        component:News17,
    },
    {
        path:'/news/18',
        component:News18,
    },
    {
        path:'/news/19',
        component:News19,
    },
    {
        path:'/news/20',
        component:News20,
    },
    {
        path:'/news/21',
        component:News21,
    },
    {
        path:'/news/22',
        component:News22,
    },
    {
        path:'/news/23',
        component:News23,
    },
    {
        path:'/news/24',
        component:News24,
    },
    {
        path:'/news/25',
        component:News25,
    },
    {
        path:'/news/26',
        component:News26,
    },
    {
        path:'/news/27',
        component:News27,
    },
    {
        path:'/news/28',
        component:News28,
    },
    {
        path:'/news/29',
        component:News29,
    },
    {
        path:'/news/30',
        component:News30,
    },
    {
        path:'/news/31',
        component:News31,
    },
    {
        path:'/news/32',
        component:News32,
    },
    {
        path:'/news/33',
        component:News33,
    },
    {
        path:'/news/34',
        component:News34,
    },
    {
        path:'/news/35',
        component:News35,
    },
    {
        path:'/news/36',
        component:News36,
    },
    {
        path:'/news/37',
        component:News37,
    },
    {
        path:'/about',
        component:About,
    },
    {
        path:'/login',
        component:Auth,
    },
    {
        path:'/news',
        component:Allnews,
    },
    {
        path:'/documents',
        component:Documents,
    },
    // {
    //     path:'registration',
    //     component:Registration,
    // },
    {
        path:'success',
        component:Success,
    },
    {
        path:'fail',
        component:Fail,
    }
]